<template>
  <div id="app">
    <div class="body fixed mobile">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <!-- <div class="spinner"><div><div></div></div></div> -->
      <!-- Main -->
      <a class="main landing" @click="dismiss">
        <div class="flexV center">
          <div class="img">
            <img v-if="logo" :src="logo">
            <img v-else
              src="https://storage.googleapis.com/tycard_assets/images/favicon-w.svg"
            />
          </div>
          <div class="img">
            <img v-if="titleImage" :src="titleImage">
            <img v-else
              src="https://storage.googleapis.com/tycard_assets/images/title-w.svg"
            />
          </div>
        </div>
        <div class="flexV center">
          <span class="fL white">頁面載入中，請稍候...</span>
          <!-- <div class="separate"></div> -->
          <!-- <span class="fM white">桃園市政府</span> -->
        </div>
      </a>
      <!-- Popup (PWA) -->
      <!-- <section id="popPWA" class="popup popPWA" v-if="deferredPrompt"> -->
      <section id="popPWA" class="popup popPWA" v-if="showPopup && ((deferredPrompt) || (browserType == 'safari' && getPWADisplayMode == 'browser'))">
        <div class="popBody">
          <div class="triangle"></div>
          <a class="close" @click="dismiss">
            <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
          </a>  
          <!-- Android disable -->
          <div class="flexV width center">
            <img v-if="logo" class="logo" :src="logo">
            <img v-else class="logo" src="https://storage.googleapis.com/tycard_assets/images/logo_pwa.png" />
          </div>
          <div class="info flexV width center">
            <span class="fXL">安裝{{ appName }}</span>
            <span  v-if="browserType === 'safari'" class="fS gray center">
              安裝{{ appName }}到您的首頁，以享有最完整之功能
              <br />按下Safari分享選單
              <!-- Android disable -->
              <img class="share" src="https://storage.googleapis.com/tycard_assets/images/share.svg" />
              ，點選「新增至主畫面」
              </span>
            <span class="fS gray center" v-if="browserType !== 'safari'">
              安裝{{ appName }}到您的首頁，以享有最完整之功能
            </span>
          </div>
          <div class="flexH width" v-if="browserType !== 'safari'">
            
            <a class="button rounded white" @click="dismiss">
              <span class="fM">暫時不要</span>
            </a>
            <a class="button rounded" @click="install">
              <span class="fM">加到首頁</span>
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",

  props: {
    msg: String,
  },
  created() {
    
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.

      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {

      this.deferredPrompt = null;
    });
    

    let self = this;
    setTimeout(function() {
      if (self.getPWADisplayMode !== 'browser') { // 已經安裝就直接往home
        
        if (self.$route.query && self.$route.query.referralCode) {
          self.$router.push({path: '/home', query: { referralCode: self.$route.query.referralCode }});
        } else {
          self.$router.push('/home');
        }
      }
    }, 1000);
    setTimeout(function() {
      // 如果五秒鐘後，還是沒有PWA Event, 那就直接跳轉?
      
      if (self.deferredPrompt === false && self.browserType !== 'safari') {
        // 也有可能是已經安裝過了

        if (self.$route.query && self.$route.query.referralCode) {
          self.$router.push({path: '/home', query: { referralCode: self.$route.query.referralCode }});
        } else {
          self.$router.push('/home');
        }
      }
      
    }, 3000);
  },
  computed: {
    appName() {
      return process.env.VUE_APP_PWA_NAME?.trim() || "紅利桃子"
    },
    logo() {
      return process.env.VUE_APP_LOGO_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_LOGO_PATH}`) : null;
    }, 
    titleImage() {
      return process.env.VUE_APP_TITLE_W_IMAGE_PATH?.trim() ? require(`@/assets/${process.env.VUE_APP_TITLE_W_IMAGE_PATH}`) : null;
    },
    getPWADisplayMode() {
      const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
      if (document.referrer.startsWith("android-app://")) {
        return "twa";
      } else if (navigator.standalone || isStandalone) {
        return "standalone";
      }
      return "browser";
    },
    browserType() {
      const ua = navigator.userAgent;
      if (ua.match(/opr\//i))
        return 'opera';
      if (ua.match(/chrome|chromium|crios/i))
        return 'chrome';
      if (ua.match(/safari/i))
        return 'safari';
      if (ua.match(/firefox|fxios/i))
        return 'firefox';
      if (ua.match(/edg/i))
        return 'edge';
      return 'No browser detection';
    }
  },
  methods: {

    async dismiss() {
      this.deferredPrompt = null;
      this.showPopup = false;
      if (this.$route.query && this.$route.query.referralCode) {
        this.$router.push({path: '/home', query: { referralCode: this.$route.query.referralCode }});
      } else {
        this.$router.push('/home');
      }
      
      
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
  data() {
    return {
      showPopup: true,
      deferredPrompt: false, // false(還沒有資訊), null: 已安裝
      isOpera: false,
      isFirefox: false,
      isSafari: false,
      isIE: false,
      isEdge: false,
      isChrome: false,
      isEdgeChromium: false,
      isBlink: false
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
